* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

@import url("https://fonts.googleapis.com/css2?family=Roboto+Condensed:wght@300&display=swap");

:root {
  --primary-white: #ffffff;
  --primary-light-grey: #f4f4f4;
  --primary-grey: #ccd1d9;
  --primary-dark-grey: #666666;
  --primary-orange: #f9ab00;
  --primary-dark: rgb(52, 58, 64);
  --primary-red: #ef4035;
  --primary-hover-red: #f33501;
  --primary-blue: #1da1f2;
  --primary-black: #000000;
  --primary-transparent-black: rgba(0, 0, 0, 0.7);
  --primary-green: green;
}

body {
  /* font-family: "Roboto Condensed", sans-serif; */
}

/* Navbar styles */
.navbar {
  background-color: #282c34;
  /* padding-left: 4.5rem !important;
  padding-right: 6rem !important; */
  --bs-navbar-padding-x: 3rem !important;

  z-index: 3;
}

.collapsing {
  height: inherit !important;
}

.logo {
  width: 12rem;
  max-height: 10rem; /* Set a maximum height for the logo */
}

.nav-link {
  letter-spacing: 0.0375rem;
  color: #fff !important; /* Use the white color for links */
  cursor: pointer;
  text-transform: uppercase;
  transition: color 0.3s ease;
}

.nav-link:hover {
  color: var(--primary-orange) !important;
}

.navbar-nav {
  list-style: none;
  display: flex;
  margin-left: auto;
}

.navbar-nav li {
  margin-right: 0.75rem;
  margin-left: 0.75rem;
  text-transform: uppercase;
}

.underline {
  width: 100%;
  height: 2px;
  background-color: var(--primary-orange);
  transform: scaleY(0);
  transform-origin: bottom center;
  transition: transform 0.3s ease;
}

.nav-link:hover .underline,
.nav-link.active .underline {
  transform: scaleY(1);
}

.nav-link {
  position: relative;
}

.underline {
  position: absolute;
  top: 0; /* Change to "bottom" if underline is desired */
  left: 0;
}

.navbar-content {
  position: relative;
}

@media (max-width: 768px) {
  .logo {
    width: 8rem;
    max-height: 8rem;
  }
  .navbar {
    background-color: red;
  }
}

/*************** HEADER ********************/

.header-wrapper {
  position: relative;
  z-index: 1;
  background: url(wall-and-laptop-background.jpg);
  background-size: cover;
  background-position: center;
  height: 100vh;
}

.main-info {
  display: flex;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  font-family: "Roboto Condensed", sans-serif;
  z-index: 1;
}

.main-info h1 {
  color: var(--primary-orange);
  text-transform: uppercase;
}

/*-------- TYPED TEXT ---------------*/
.typed-text {
  font-size: 2rem;
  color: var(--primary-white);
}

@media (max-width: 768px) {
  .main-info h1 {
    font-size: 1.7rem !important;
  }
  .typed-text {
    font-size: 1.3rem !important;
  }
}

/*-------- HEADER MAIN OFFER BUTTON ---------------*/
.btn-main-offer {
  border: 1px solid var(--primary-red);
  text-transform: uppercase;
  border-radius: 0;
  padding: 0.625rem 0.75rem 0.75rem 0.75rem;
  color: var(--primary-white);
  margin: 2rem;
  text-decoration: none;
  z-index: 9999 !important;
}

.btn-main-offer:hover {
  text-decoration: none !important;
  background-color: var(--primary-hover-red) !important;
  color: var(--primary-white) !important;
  transition: 0.2s ease-in-out !important;
}

/*-------- PARTICLES JS ---------------*/
.tsparticles-canvas-el {
  position: absolute;
  height: 100vh;
  width: 100vw;
  z-index: 1;
  background-color: transparent; /* Transparent Background */
}

#tsparticles {
  position: absolute;
  height: 100vh;
  width: 100vw;
  z-index: 0; /* or higher value than header */
  background-color: transparent; /* Transparent Background */
}

@media (max-width: 768px) {
  .particles-canvas {
    display: none;
  }
}

/************************ ABOUT ME *********************************/

.about-heading {
  color: var(--primary-orange);
  text-transform: uppercase;
}

.photo-wrap {
  display: flex;
  justify-content: center;
  align-items: center;
  max-width: 100%; /* Stellt sicher, dass das Bild nicht breiter als der Anzeigebereich wird */
  position: sticky;
  top: 10rem; /* Stick to the top of the container */
  background-color: white; /* Add a background color to the sticky element */
  z-index: 1; /* Ensure the sticky element is above other content */
}

.profile-img {
  width: 100%; /* Füllt die maximale Breite des übergeordneten Elements aus */
  height: auto; /* Lässt die Höhe automatisch anhand der Breite berechnen, um das Seitenverhältnis zu erhalten */
}

@media (max-width: 468px) {
  .profile-img {
    width: 20rem; /* Du kannst dies auf einen anderen Wert ändern, je nach Bedarf */
  }
  .about-heading {
    text-align: center;
    margin-top: 2rem;
  }
}

/*-------- SERVICES ---------------*/
.services {
  text-align: center;
  padding-bottom: 2rem;
  background: var(--primary-light-grey);
}

.services h1 {
  color: var(--primary-dark);
  text-transform: uppercase;
}

.services .circle {
  position: relative;
  margin: 0.375rem auto;
  background: var(--primary-orange);
  border-radius: 50%;
  width: 3.125rem;
  height: 3.125rem;
}

.services .icon {
  font-size: 1.4rem;
  color: var(--primary-white);
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.services .box {
  height: 16rem;
  overflow: hidden;
  text-overflow: ellipsis;
  border-bottom: 5px solid var(--primary-orange);
  padding: 0.625rem;
  margin-bottom: 1.875rem;
  background: var(--primary-white);
  transition: 0.3s ease-in-out;
}

.services .box:hover {
  background: var(--primary-orange);
  border-bottom: 0.3125rem var(--primary-dark);
}

.services .box:hover .icon {
  color: var(--primary-dark);
}

.services .box:hover .circle {
  background: var(--primary-white);
}

.services .box:hover p {
  color: var(--primary-white);
}

@media (max-width: 468px) {
  .services {
    max-width: 100%; /* Du kannst dies auf einen anderen Wert ändern, je nach Bedarf */
  }
}

/*-------- Experience ---------------*/
.experience {
  padding: 0.7rem;
}

.experience-wrapper {
  position: relative;
  padding: 3.125rem 0;
  overflow: hidden;
}

.experience h1 {
  text-transform: uppercase;
  color: var(--primary-orange);
}

.experience-wrapper::before {
  position: absolute;
  content: "";
  top: 0;
  left: 50%;
  margin-left: -0.0625rem;
  width: 0.125rem;
  height: 100%;
  background: var(--primary-grey);
  z-index: 1;
}

.timeline-block {
  width: calc(50% + 0.5rem);
  display: flex;
  justify-content: space-between;
  clear: both;
}

.timeline-block-right {
  float: right;
}

.timeline-block-left {
  float: left;
  direction: rtl;
}

.marker {
  width: 1rem;
  height: 1rem;
  border-radius: 50%;
  border: 0.3125rem solid var(--primary-dark);
  background: var(--primary-orange);
  margin-top: 0.625rem;
  z-index: 3;
}

.timeline-content {
  width: 95%;
  padding: 0 0.9375rem;
  color: var(--primary-dark-grey);
  direction: ltr;
}

.timeline-content h3 {
  margin-top: 0.3125rem;
  color: var(--primary-red);
  font-weight: 900;
}

/*-------- MEDIA ---------------*/
@media (max-width: 768px) {
  .experience .container {
    padding-left: 0;
  }

  .experience-wrapper::before {
    left: 0.5rem;
    margin-bottom: 1.875rem;
  }
  .timeline-block-right {
    float: none;
    width: 100%;
  }

  .timeline-block-left {
    float: none;
    direction: ltr;
    width: 100%;
  }
}

/************************ PORTFOLIO *********************************/
.portfolio-wrapper {
  background: var(--primary-light-grey);
  padding: 1rem 0;
}

.portfolio-image-box {
  position: relative;
  margin: 1rem;
  overflow: hidden;
}

.portfolio-image {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border: 1px solid var(--primary-orange);
  transition: 0.3s ease-in-out; /* Add transition for smooth effect */
}

.portfolio-image-container {
  position: relative;
}

/*-------- OVERFLOW BOX ---------------*/
.overlay {
  position: absolute;
  opacity: 0;
  background: var(--primary-black);
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  border: 1px solid var(--primary-red);
  cursor: pointer;
  box-sizing: border-box;
  transition: 0.3s ease-in-out; /* Add transition for smooth effect */
}

.portfolio-image-box:hover .overlay {
  opacity: 0.6;
}

.portfolio-image-box:hover .portfolio-image {
  opacity: 0.5; /* Adjust opacity as needed */
}

.portfolio-icon {
  position: absolute;
  opacity: 0;
  top: 40%;
  left: 47%;
  color: var(--primary-orange);
  font-size: 1.5rem;
}

.portfolio-image-box:hover .portfolio-icon {
  opacity: 70%;
  transition: 0.3s ease-in-out;
  cursor: pointer;
}

.portfolio-image-box {
  position: relative;
}

/*-------- POPUP MODAL ---------------*/

.modal {
  display: flex !important;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.7);
  justify-content: center;
  align-items: center;
  /* z-index: 1000000; */
}

.modal-content {
  background-color: white;
  padding: 20px;
  border-radius: 5px;
  max-width: 600px; /* Beispiel für eine maximale Breite */
  margin: 0 auto; /* Zentriert das Popup horizontal */
}

.close {
  position: absolute;
  top: 10px;
  right: 10px;
  cursor: pointer;
}

.hyper-link {
  color: var(--primary-blue);
  text-decoration: none;
  transition: text-decoration 0.3s ease;
  cursor: pointer;
}

.hyper-link:hover {
  text-decoration: underline;
}

/*-------- TESTIMONIALS ---------------*/

.testimonials {
  background-image: url(testimonials-bg.jpg);
  background-size: cover;
  background-color: var(--primary-black);
  text-align: center;
  padding-bottom: 4rem;
  margin-bottom: 0;
  width: 100%;
}

.testimonials h1 {
  color: var(--primary-orange);
  padding: 3rem 0;
  text-transform: uppercase;
}

.testimonials-content {
  text-align: center;
  max-width: 53.125rem;
  margin: 0 auto 2rem auto;
  background: var(--primary-transparent-black);
}

.myCarousel {
  color: var(--primary-white);
  background: transparent;
  margin: auto;
  width: 54%;
  padding: 6% 5% 8%;
  height: auto;
}

.carousel .slide img {
  width: 10rem !important;
  border-radius: 50%;
}

.myCarousel h3 {
  color: var(--primary-orange);
  font-weight: 600;
  text-transform: uppercase;
}

.myCarousel p {
  color: var(--primary-grey);
}

.carousel .slide {
  background: transparent !important;
  height: 100% !important;
  padding-top: 2rem;
}

.carousel-root {
  margin: auto !important;
  margin-top: 3% !important;
}

/*-------- CONTACTS COMPONENTS ---------------*/

.contacts {
  background: var(--primary-dark);
  padding: 3rem 0;
  text-align: center;
}

.contacts h1 {
  color: var(--primary-orange);
  padding: 1.3rem 0;
  text-transform: uppercase;
}

.contacts p {
  color: var(--primary-light-grey);
  font-size: 1.2rem;
}

/*-------- INPUTS ---------------*/

.contacts input,
.contacts textarea {
  width: 100%;
  box-sizing: border-box;
  background: none;
  resize: none;
  border: 0;
  border-bottom: 0.125rem solid var(--primary-dark-grey);
  border-radius: 0;
  color: var(--primary-orange);
  outline: 0 !important;
  box-shadow: none !important;
  margin-bottom: 1rem;
  padding-left: 0 !important;
  &::placeholder {
    color: var(--primary-dark-grey);
  }
}

.contacts .container {
  padding: 4rem 0;
}

.contacts input:focus,
.contacts textarea:focus {
  background: transparent;
  color: var(--primary-orange);
  border-bottom: 0.125rem solid var(--primary-dark-grey);
}

.contacts textarea {
  height: 12.7rem !important;
}

.contact-btn {
  margin: 1rem 0 0 0;
  width: 100%;
  background: none;
}

.line {
  position: relative;
  top: -0.89rem;
  width: 0;
  height: 0.125rem;
  background: var(--primary-orange);
  display: inline-block;
}

input:focus + .line,
textarea:focus + .line {
  width: 100%;
  transition: width 0.5s ease-in-out;
}

input.form-control {
  margin: -0.22rem 0;
}

textarea.form-control {
  margin: -0.22rem 0;
}

.error-message {
  color: var(--primary-white);
  background-color: var(--primary-hover-red);
  text-align: left;
}

/*-------- MEDIA ---------------*/

@media (max-width: 768px) {
  .contacts {
    overflow-x: hidden;
  }
  .contacts .container {
    padding: 0 1rem;
  }
  .contacts p {
    padding: 0 0.4rem;
  }
  /* For Webkit browsers Chrome & Safari */
  ::-webkit-scrollbar {
    width: 0;
    background: transparent;
  }
  /* For Firefox */
  .contacts {
    scrollbar-width: none;
  }
}

.success-message {
  display: inline-block;
  margin-top: 1.6rem auto;
  padding: 0.8rem;
  background-color: #003366; /* Green background color */
  color: #fff; /* White text color */
  border-radius: 0.5rem; /* Rounded corners */
  font-size: 1rem;
  font-weight: bold;
  transition: 0.5s ease-in-out;
  text-align: justify;
}

/*-------- FOOTER ---------------*/
.footer {
  background: var(--primary-black);
  padding: 3rem;
  color: var(--primary-dark-grey);
}

.footer a {
  text-decoration: none;
  color: var(--primary-dark-grey);
}

.footer a:hover {
  text-decoration: none;
  color: var(--primary-orange);
  cursor: pointer;
}

.footer p {
  margin-bottom: 0;
}
